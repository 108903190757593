<template>
  <div>
    <validation-observer ref="createCode">
      <b-form>
        <b-modal
          dialog-class="code-modal-dialog"
          content-class="code-modal-content"
          v-model="codeModal"
          scrollable
          size="sm"
          ref="createModal"
          @hide="toggleCodeDialog(false)"
        >
          <template #modal-title>
            <strong>إضافة رمز</strong>
          </template>
          <b-form-group label="اختر الحزم" label-for="packagesIds">
            <validation-provider #default="{ errors }" name="اختر الحزم" rules="required">
              <v-select
                id="packageId"
                v-model="packageId"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                :options="packagesList"
                :reduce="(item) => item.id"
                @input="getPackageInfo(packageId)"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="الحسم" label-for="discountRate">
            <b-input-group append="%">
              <b-form-input
                id="discountRate"
                v-model="codeForm.discountRate"
                type="number"
                :disabled="!packageId"
                @input="changePrice()"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group label="تاريخ النهاية" label-for="endDate">
            <b-form-input id="endDate" v-model="endDate" disabled />
          </b-form-group>
          <b-form-group label="الكلفة" label-for="price">
            <b-input-group append="ل.س">
              <b-form-input id="price" v-model="displayedPrice" type="number" disabled />
            </b-input-group>
          </b-form-group>

          <template #modal-footer>
            <b-button
              size="md"
              type="submit"
              variant="primary"
              @click.prevent="SubmitAddCode()"
            >
              توليد الرمز
            </b-button>
            <b-button
              @click="toggleCodeDialog(false)"
              size="md"
              variant="outline-primary"
            >
              تراجع
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>
.code-modal-dialog {
  margin: 0 !important;
  max-height: 100vh !important;
  height: 100vh;
}
.code-modal-content {
  max-height: 100vh !important;
  border-radius: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { BButton, BFormGroup, BFormInput, BForm, BInputGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  data: () => ({
    codeModal: false,
    required,
    packageId: null,
    displayedPrice: 0,
    endDate: null,
  }),
  created() {
    localize("ar");
  },
  computed: {
    ...mapGetters(["isCodeDialog", "codeForm", "packagesList", "packagesList"]),
  },
  methods: {
    ...mapActions(["toggleCodeDialog", "setCode"]),
    SubmitAddCode() {
      this.$refs.createCode.validate().then((success) => {
        if (success) {
          this.codeForm.packagesIds[0] = this.packageId;
          this.setCode(this.codeForm);
          this.$refs.createModal.hide();
          this.$bvToast.toast(`تم توليد كود بنجاح`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
          this.packageId = null;
          this.displayedPrice = 0;
          this.endDate = null;
        }
      });
    },
    getPackageInfo(packageId) {
      var item = this.packagesList.find((item) => item.id == packageId);
      this.endDate = new Date(item.endDate).toISOString().substr(0, 10);
      this.codeForm.price = item.price;
      this.displayedPrice = item.price;
    },
    changePrice() {
      if (this.codeForm.discountRate !== null) {
        this.displayedPrice =
          this.codeForm.price - this.codeForm.price * (this.codeForm.discountRate / 100);
      }
    },
  },
  watch: {
    isCodeDialog(newVal) {
      this.codeModal = newVal;
      this.packageId = null;
    },
  },
};
</script>
