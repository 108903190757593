<template>
  <div v-if="mainCodesList.length > 0">
    <b-card no-body>
      <b-card-header class="py-0">
        <b-button
          size="sm"
          variant="flat-secondary"
          class="btn-icon rounded-circle ml-auto"
          style="padding: 0.5rem 10px !important"
          :disabled="isNotSelectedRows"
          @click="getSelectedRow()"
        >
          <unicon name="trash-alt" width="18"></unicon>
        </b-button>
      </b-card-header>
      <b-card-body class="p-0">
        <vue-good-table
          @on-selected-rows-change="selectionChanged"
          :columns="columns"
          :rows="codesActivePage"
          :rtl="false"
          :small="true"
          styleClass="vgt-table condensed"
          ref="codes-table"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'studentName'">
              {{
                !!props.row.dateActivated
                  ?props.row.studentName
                  : "لا يوجد"
              }}
            </span>
            <span v-else-if="props.column.field === 'dateActivated'">
              {{
                !!props.row.dateActivated
                  ? new Date(props.row.dateActivated).toISOString().substr(0, 10)
                  : "لا يوجد"
              }}
            </span>
            <span v-else-if="props.column.field === 'price'">
              {{ props.row.price - props.row.price * props.row.discountRate | numFormat }} ل.س 
            </span>
            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge class="px-1" :variant="statusVariant(!!props.row.dateActivated)">
                {{ !!props.row.dateActivated ? "مفعل" : "غير مفعل" }}
              </b-badge>
            </span>
            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'details'">
              <b-button
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                style="padding: 2px 6px !important"
              >
                <unicon name="ellipsis-v" width="18"></unicon>
              </b-button>
            </span> -->

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        :value="codesFirstPage"
        :total-rows="mainCodesList.length"
        :per-page="codesPageLength"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value) => updateCodesPagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
      </b-pagination>
    </b-col>
    <codeDialog />
  </div>
  <div v-else class="text-center justify-content-center mt-2">
      <h4>لا يوجد أكواد متاحة</h4>
    </div>
</template>

<script>
import {
  BBadge,
  BPagination,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import codeDialog from "./create-code";
export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    codeDialog,
  },
  data() {
    return {
      ids : [],
      isNotSelectedRows: true,
      columns: [
        {
          label: "الرمز",
          field: "code",
        },
        {
          label: "اسم الطالب",
          field: "studentName",
        },
        {
          label: "تاريخ التفعيل",
          field: "dateActivated",
          sortable: false,
        },
        {
          label: "اسم المندوب",
          field: "posName",
        },
        {
          label: "القيمة المدفوعة",
          field: "price",
          type: "number",
          thClass: "vgt-left-align sortable",
          tdClass: "vgt-left-align",
          sortable: true,
        },
        {
          label: "حالة الإشتراك",
          field: "status",
          sortable: false,
        },
        // {
        //   label: "تفاصيل",
        //   field: "details",
        //   sortable: false,
        // },
      ],
    };
  },
  mounted() {
    this.fetchCodes();
    this.getPackagesList();
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      text: "رمز جديد",
      fetchingFunction: this.toggleCodeDialog,
      placeHolder: "ابحث عن رمز محدد",
      value: "",
      filterFunc: this.filterCodes,
    });
  },
  
  computed: {
    ...mapGetters(["mainCodesList","codesFirstPage","codesActivePage","codesPageLength"]),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },
  methods: {
    ...mapActions(["fetchCodes", "toggleCodeDialog","getPackagesList", "filterCodes","updateCodesPagination","removeCodes"]),
    selectionChanged() {
      this.isNotSelectedRows = !this.$refs["codes-table"].selectedRows.length;
    },
    getSelectedRow() {
      this.ids = [];
      this.$refs["codes-table"].selectedRows.forEach(element => {
        this.ids.push(element.id)
      });
      this.removeCodes(this.ids)
    },
  },
};
</script>
